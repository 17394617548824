import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensors,
  useSensor,
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';
import { Box } from './Box';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  TextField,
  Typography,
  Tooltip,
  useTheme,
} from '@mui/material';

import {
  ChevronDown16Regular,
  ChevronDown20Regular,
  ChevronUp16Regular,
  ChevronUp20Regular,
  People16Regular,
  Person16Filled,
} from '@fluentui/react-icons';
import { Approver, ApproversList } from '../types';
import { Button } from './button';
import { SortableItem } from './list/SortableItem';
import { useLazyGetApproversListWithParamsQuery } from '../services';
import useOutsideClick from '../hooks/useOutsideClick';

export const EditApprovers = ({
  approvers,
  selectedApprovers,
  setSelectedApprovers,
  isApproversListValid,
  setIsApproversListValid,
  singleApprovalSelect = false,
  onSelect,
}: {
  approvers: ApproversList;
  selectedApprovers?: Approver[];
  setSelectedApprovers?: any;
  isApproversListValid?: boolean;
  setIsApproversListValid?: any;
  singleApprovalSelect?: boolean;
  onSelect?: (newApprover: Approver) => void;
}) => {
  const theme = useTheme();

  const handleClickOutside = () => {
    setApproversSelectListHidden(true);
  };

  const defaultSelectLabel = '- Add an approver -';
  const [selectLabel, setSelectLabel] = useState(defaultSelectLabel);

  const ref = useOutsideClick(handleClickOutside);

  const [filteredLocalApprovers, setFilteredLocalApprovers] = useState<
    Approver[]
  >(approvers.value);

  const [approversSearchQuery, setApproversSearchQuery] = useState<string>('');

  const [approversSelectListHidden, setApproversSelectListHidden] =
    useState<boolean>(true);

  const selectButtonBorderColor = isApproversListValid
    ? theme.palette.info[600]
    : theme.palette.warning.main;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 150, tolerance: 5 },
    })
  );

  const handleOnDelete = (index: number, approver: Approver) => {
    var array = [...selectedApprovers];
    if (index !== -1) {
      array.splice(index, 1);
      setSelectedApprovers(array);
      setFilteredLocalApprovers([...filteredLocalApprovers, approver]);

      if (array.length == 0) {
        setIsApproversListValid(false);
      }
    }
  };

  const [
    getApproversBySearch,
    {
      isSuccess: isSuccessSearch,
      data: dataSearch,
      isLoading: isLoadingSearch,
    },
  ] = useLazyGetApproversListWithParamsQuery();

  const approversSearch = (searchQuery: string) => {
    if (
      (searchQuery.length === 0 || searchQuery === '') &&
      searchQuery.length === 0
    ) {
      setFilteredLocalApprovers(approvers.value);
    } else {
      getApproversBySearch({ searchFor: searchQuery });

      if (
        !isLoadingSearch &&
        isSuccessSearch &&
        (dataSearch?.value?.length ?? 0) > 0
      ) {
        setFilteredLocalApprovers(dataSearch.value);
      } else {
        setFilteredLocalApprovers([]);
      }
    }
  };
  useEffect(() => {
    if (approversSearchQuery === '') {
      const newApproversList = approvers.value.filter(function (
        _approver: Approver
      ) {
        return !selectedApprovers.find(function (_selectedApprover: Approver) {
          return _approver._Display === _selectedApprover._Display;
        });
      });

      setFilteredLocalApprovers(newApproversList);
    } else {
      getApproversBySearch({ searchFor: approversSearchQuery });

      if (
        !isLoadingSearch &&
        isSuccessSearch &&
        (dataSearch?.value?.length ?? 0) > 0
      ) {
        if (selectedApprovers.length === 0) {
          setFilteredLocalApprovers(dataSearch.value);
        } else {
          const newApproversList = dataSearch.value.filter(
            (_approver: Approver) => {
              return (
                (_approver._Display
                  .toLowerCase()
                  .includes(approversSearchQuery.toLowerCase()) ||
                  _approver.Department?.toLowerCase().includes(
                    approversSearchQuery.toLowerCase()
                  )) &&
                !selectedApprovers.some((_obj) => _obj.ID === _approver.ID)
              );
            }
          );

          setFilteredLocalApprovers(newApproversList);
        }
      } else {
        setFilteredLocalApprovers([]);
      }
    }
  }, [approversSearchQuery]);

  const handleSort = (index: number, approver: Approver) => {
    var array = [...filteredLocalApprovers];
    if (index !== -1) {
      array.splice(index, 1);
      setFilteredLocalApprovers(array);
      setSelectedApprovers([...selectedApprovers, approver]);
      setIsApproversListValid(true);
    }
  };

  function handleDragEnd({ active, over }) {
    if (active.id !== over.id) {
      setSelectedApprovers((selectedApprovers) => {
        const oldIndex = selectedApprovers.findIndex(
          (item: Approver) => item.ID === active.id
        );
        const newIndex = selectedApprovers.findIndex(
          (item: Approver) => item.ID === over.id
        );

        return arrayMove(selectedApprovers, oldIndex, newIndex);
      });
    }
  }

  const ApproversHierarchicalList = ({
    approversList,
  }: {
    approversList: Approver[];
  }) => {
    // Group items by department
    const groupedItems = approversList?.reduce((acc, item) => {
      let { Department } = item;

      if (Department === null || Department === '')
        Department = '*No department*';
      if (!acc[Department]) {
        acc[Department] = [];
      }
      acc[Department].push(item);
      return acc;
    }, {});

    const [open, setOpen] = useState({});

    const handleClick = (index) => {
      setOpen((prevOpen) => ({
        ...prevOpen,
        [index]: !prevOpen[index],
      }));
    };

    return (
      <>
        {Object.keys(groupedItems).map((department) => (
          <div key={department}>
            <ListItem
              disablePadding
              key={`main-${department}`}
              onClick={() => handleClick(department)}
            >
              <ListItemButton>
                {open[department] ? (
                  <ChevronUp16Regular />
                ) : (
                  <ChevronDown16Regular />
                )}
                <People16Regular style={{ marginLeft: '0.5rem' }} />
                <Typography variant='body2' ml={1}>
                  {department}
                </Typography>
              </ListItemButton>
            </ListItem>
            <Collapse in={!open[department]} timeout='auto' unmountOnExit>
              {groupedItems[department].map(
                (approver: Approver, index: number) => (
                  <ListItem
                    disablePadding
                    key={approver.ID}
                    sx={{ paddingLeft: '2.5rem' }}
                  >
                    <ListItemButton
                      onClick={() => {
                        if (singleApprovalSelect) {
                          setSelectLabel(approver._Display);
                          onSelect(approver);
                        } else {
                          handleSort(index, approver);
                        }
                      }}
                    >
                      <Person16Filled />
                      <Tooltip title={approver.Mail} placement='top' arrow>
                        <Typography variant='body2' ml={1}>
                          {approver._Display}
                        </Typography>
                      </Tooltip>
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </Collapse>
          </div>
        ))}
      </>
    );
  };

  return (
    <Box width='25rem' background='none'>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <List
          dense
          sx={{
            width: '25rem',
            borderRadius: '0.5rem',
            border: `1px solid ${theme.palette.background.paper}`,
            padding: 0,
            overflow: 'auto',
            maxHeight: '12rem',
          }}
        >
          <SortableContext items={selectedApprovers.map((item) => item.ID)}>
            {selectedApprovers?.map((approver, index) => (
              <SortableItem
                key={`'sortable_' + ${approver.ID}`}
                index={index}
                id={approver.ID}
                name={approver._Display}
                approver={approver}
                onDelete={() => handleOnDelete(index, approver)}
              />
            ))}
          </SortableContext>
        </List>
      </DndContext>
      <div ref={ref}>
        <Box background='none'>
          <Button
            color='info'
            variant='outlined'
            endIcon={
              approversSelectListHidden ? (
                <ChevronUp20Regular color={theme.palette.primary.main} />
              ) : (
                <ChevronDown20Regular color={theme.palette.primary.main} />
              )
            }
            sx={{
              fontFamily: 'Avenir Roman',
              justifyContent: 'space-between',
              borderColor: selectButtonBorderColor,
              width: '25rem',
            }}
            onClick={() => {
              setApproversSelectListHidden(!approversSelectListHidden);
            }}
          >
            {selectLabel}
          </Button>
          <Box
            background='none'
            style={{
              borderRadius: '0.5rem',
              border: `0.063rem solid ${theme.palette.info[600]}`,
              display: approversSelectListHidden ? 'none' : '',
              position: 'absolute',
              background: 'white',
              marginTop: '2.813rem',
              boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.15)',
            }}
          >
            <TextField
              size='small'
              sx={{
                margin: '0.5rem',
                padding: '5px !important',
              }}
              onChange={(event) => {
                setApproversSearchQuery(event.target.value);
              }}
            />
            <List
              sx={{
                width: '25rem',
                borderRadius: '0.5rem',
                padding: 0,
                overflow: 'scroll',
                maxHeight: '11rem',
              }}
            >
              <ApproversHierarchicalList
                approversList={filteredLocalApprovers}
              />
            </List>
          </Box>
        </Box>
      </div>
    </Box>
  );
};
